import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { fromMarkdown } from 'mdast-util-from-markdown'
import { Link } from '../Link'
import { BodyText2 } from '../Heading'

import './index.scss'

export const ToC: React.FC<{
  content: string
  url: string
  maxDepth?: number
}> = ({ url, content, maxDepth = 2 }) => {
  const [active, setActive] = useState('')

  const headings = useMemo(() => {
    return fromMarkdown(content).children.filter(
      (node: any) => node.type === 'heading' && node.depth <= maxDepth
    )
  }, [content, maxDepth])

  const calcPosition = useCallback(() => {
    const contentEl = document.getElementById('news-content')

    if (!contentEl) return

    headings.forEach((node: any, index) => {
      const value = node.children?.[0].value

      const el = document.getElementById('title_' + value)

      if (!el) return false

      const rect = el.getBoundingClientRect()

      if (rect.top < 100 || index === 0) {
        setActive(value)
      }

      return
    })
  }, [headings])

  useEffect(() => {
    let id: any = null
    const run = () => {
      clearTimeout(id)
      id = setTimeout(() => {
        calcPosition()
      }, 100)
    }

    window.addEventListener(
      'scroll',
      () => {
        run()
      },
      true
    )
    window.addEventListener(
      'resize',
      () => {
        run()
      },
      true
    )

    run()

    return () => {
      window.removeEventListener('scroll', calcPosition, true)
      window.removeEventListener('resize', calcPosition, true)
    }
  }, [calcPosition])

  return (
    <ul className="toc">
      {headings.map((heading, index) => {
        const value = heading.children?.[0].value
        return (
          <li
            className={`t${heading.depth} ${active === value ? 'active' : ''}`}
            onClick={() => setActive(value)}
            key={index.toString()}>
            <Link to={url + '#title_' + value} key={index.toString()}>
              <BodyText2>{value}</BodyText2>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
