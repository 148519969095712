import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HeadingProps } from 'react-markdown/lib/ast-to-react'

export const Markdown: React.FC<React.ComponentProps<typeof ReactMarkdown>> = (
  props
) => {
  const genID = (props: React.PropsWithChildren<HeadingProps>) => {
    return 'title_' + props.children.join('')
  }

  return (
    <ReactMarkdown
      components={{
        h1: (props) => {
          const id = genID(props)

          return <h1 id={id}>{props.children}</h1>
        },
        h2: (props) => {
          const id = genID(props)

          return <h2 id={id}>{props.children}</h2>
        },
        h3: (props) => {
          const id = 'title_' + props.children.join('')

          return <h3 id={id}>{props.children}</h3>
        },
        h4: (props) => {
          const id = 'title_' + props.children.join('')

          return <h4 id={id}>{props.children}</h4>
        },
        h5: (props) => {
          const id = 'title_' + props.children.join('')

          return <h5 id={id}>{props.children}</h5>
        },
        h6: (props) => {
          const id = 'title_' + props.children.join('')

          return <h6 id={id}>{props.children}</h6>
        },
      }}
      {...props}
    />
  )
}
