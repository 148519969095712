import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { TwitterShareButton, LinkedinShareButton } from 'react-share'
import { motion, Variants } from 'framer-motion'

import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { BodyText2, Heading4, Subtitle1 } from '../../components/Heading'

import { NewsData, NewsTag } from '../../components/NewsList'

import { Layout } from '../../components/Layout'
import { CircleArrowIcon } from '../../components/Icon'
import { formatTime, getLocalizationId, isBrowser } from '../../utils'
import { Link } from '../../components/Link'

import { Markdown } from '../../components/Markdown'
import { ToC } from '../../components/ToC'
import { useI18n } from '../../i18n'
import { WechatCode } from '../../components/WechatCode'
import { useCardTransition } from '../../animation'
import './index.scss'

const Share: React.FC<{ className?: string }> = ({ className = '' }) => {
  const url = isBrowser() ? window.location.href : ''
  const [copied, setCopied] = useState(false)
  const { isChinese } = useI18n()

  const copyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  const transition = useCardTransition()
  const qrCodeVariants: Variants = {
    initial: {
      y: -60,
      opacity: 0,
    },
    hover: {
      y: -50,
      opacity: 1,
    },
  }

  return (
    <div className={`share ${className}`}>
      <Divider></Divider>
      <Subtitle1>{isChinese ? '分享文章' : 'Share Article'}</Subtitle1>
      <div className="share-actions">
        <motion.button
          className="wechat-btn"
          transition={transition}
          initial="initial"
          whileHover="hover">
          <i className="wechat"></i>
          <WechatCode variants={qrCodeVariants} />
        </motion.button>
        <TwitterShareButton url={url}>
          <i className="twitter"></i>
        </TwitterShareButton>
        <LinkedinShareButton url={url}>
          <i className="linkedin"></i>
        </LinkedinShareButton>
        <button
          onClick={() => {
            copyToClipboard(url)
            setCopied(true)
          }}>
          <i className="link"></i>
        </button>
        <BodyText2>{copied ? (isChinese ? '已复制' : 'Copied') : ''}</BodyText2>
      </div>
    </div>
  )
}

export const NewsSingle: React.FC<
  PageData<
    {},
    {
      article: ResponseData<NewsData>
      next: ResponseData<NewsData> | null
      prefix: string
      showToc?: boolean
      isReport?: boolean
      isInsights?: boolean
    }
  >
> = ({ pageContext }) => {
  const { article, next, prefix, showToc = false } = pageContext
  const localeId = getLocalizationId(article)
  const tagData = article.attributes.tag ? article.attributes.tag.data : null

  return (
    <Layout
      className="news-single"
      switchUrl={localeId ? `${prefix}/${localeId}` : ''}>
      <Helmet>
        <title>{article.attributes.title}</title>
      </Helmet>
      <div className="news-container">
        <div className="header">
          <div className="title">
            {tagData && <NewsTag {...tagData.attributes} />}
          </div>
          <Heading4 as="h1">{article.attributes.title}</Heading4>
          <BodyText2>{formatTime(article.attributes.date)}</BodyText2>
          {showToc && (
            <ToC
              content={article.attributes.content}
              url={prefix + '/' + article.id}></ToC>
          )}
          <Share className="desktop" />
        </div>
        <div className="news-content" id="news-content">
          <div className="content">
            <Markdown>{article.attributes.content}</Markdown>
          </div>
          <Share className="mobile" />
          {next && (
            <Link
              to={`${prefix}/${next.id}`}
              lang={next.attributes.locale}
              external={true}>
              <div className="next-page">
                <BodyText2>{next.attributes.title}</BodyText2>
                <Button border>
                  <CircleArrowIcon direction="right"></CircleArrowIcon>
                </Button>
              </div>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}
